import Image from 'next/image';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import cx from 'classnames';

import HeaderLink from './HeaderLink';
import {useRequiredUser} from '../../context/UserContext';

export default function MobileMenu() {
  const {pathname} = useRouter();
  const {user} = useRequiredUser();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      document.querySelector('body')!.classList.add('noscroll');
    } else {
      document.querySelector('body')!.classList.remove('noscroll');
    }
  }, [menuOpen]);

  if (menuOpen) {
    return (
      <div className="fixed top-0 left-0 bottom-0 right-0  bg-superLightBrown z-50 px-6 py-10">
        <div className="flex justify-between items-center">
          <Image src="/assets/logo.svg" width={150} height={100} alt="Logo" />

          <div onClick={() => setMenuOpen(false)} className="p-3">
            <Image
              src="/assets/icons/close.svg"
              width={19}
              height={18}
              alt="Icona chiudi menu"
            />
          </div>
        </div>

        <div>
          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink path="/" active={pathname === '/'} className="text-lg">
              Home
            </HeaderLink>
          </div>

          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink
              path={`/curriculum/${user.curriculum.id}`}
              active={pathname.includes('/curriculum')}
              className="text-lg"
            >
              Corsi
            </HeaderLink>
          </div>

          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink
              path="/learning-area"
              active={pathname.includes('/learning-area')}
              className="text-lg"
            >
              Learning Area
            </HeaderLink>
          </div>

          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink
              path="/mdcodes"
              active={pathname.includes('/mdcodes')}
              className="text-lg"
            >
              MD Codes™
            </HeaderLink>
          </div>

          {/* <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink
              path="/formula"
              active={pathname.includes('/formula')}
              className="text-lg"
            >
              MD Codes™ Formula
            </HeaderLink>
          </div> */}

          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink
              path="/chi-siamo"
              active={pathname === '/chi-siamo'}
              className="text-lg"
            >
              Chi Siamo
            </HeaderLink>
          </div>

          <div className="border-b border-b-lightBrown py-6 flex justify-center items-center">
            <HeaderLink path="/profile" active={pathname === '/profile'}>
              <div className="flex items-center gap-x-2">
                <Image
                  src="/assets/icons/user.svg"
                  width={20}
                  height={20}
                  alt="Icona profilo"
                  className={cx({'mb-1': pathname === '/profile'})}
                />
                <p
                  className={cx('mb-[2px]', {
                    'mt-[2px]': pathname === '/profile',
                  })}
                >
                  Profilo
                </p>
              </div>
            </HeaderLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={() => setMenuOpen(true)}>
      <Image
        src="/assets/icons/burger.svg"
        width={41}
        height={36}
        alt="Icona menu"
      />
    </div>
  );
}
