import {ButtonHTMLAttributes} from 'react';
import cx from 'classnames';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | JSX.Element;
  onClick?: () => void;
  loading?: boolean;
  variant: 'dark' | 'light';
}

export default function Button({
  children,
  loading,
  variant,
  className,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={cx(
        'relative',
        'text-white font-medium',
        'rounded-[15px] py-[16px] px-[32px]',
        {
          'bg-darkBrown': variant === 'dark',
          'bg-brown': variant === 'light',
        },
        'cursor-pointer disabled:cursor-not-allowed',
        className
      )}
      {...rest}
    >
      <span className={cx({'opacity-0': loading})}>{children}</span>

      {loading && (
        <div className="absolute flex top-1/2 left-1/2 w-6 h-6 -translate-x-1/2 -translate-y-1/2">
          <span className="w-6 h-6 border-2 border-brown border-b-lightBrown rounded-full animate-spin" />
        </div>
      )}
    </button>
  );
}
