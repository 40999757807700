import {useEffect, useState} from 'react';

const useResponsive = () => {
  const [width, setWidth] = useState<number>(() => {
    if (typeof window === 'undefined') {
      return 1220;
    }
    return window.innerWidth;
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return {
    md: width >= 768,
    lg: width >= 1024,
  };
};

export default useResponsive;
