import cx from 'classnames';
import Image from 'next/image';

type EnrollmentStatusProps = {
  status:
    | 'pending'
    | 'approved'
    | 'completed'
    | 'pending_signed'
    | 'waiting_list';
  bgHidden?: boolean;
  className?: string;
};

const textMap: Record<EnrollmentStatusProps['status'], string> = {
  pending: 'Richiesta in attesa',
  approved: 'Richiesta approvata',
  completed: 'Corso completato',
  pending_signed: 'Iscrizione incompleta',
  waiting_list: "Iscritto alla lista d'attesa",
};

const iconMap: Record<EnrollmentStatusProps['status'], string> = {
  pending: '/assets/icons/enrollment-pending.svg',
  approved: '/assets/icons/enrollment-approved.svg',
  completed: '/assets/icons/enrollment-completed.svg',
  pending_signed: '/assets/icons/enrollment-pending.svg',
  waiting_list: '/assets/icons/enrollment-pending.svg',
};

export default function EnrollmentStatus({
  status,
  bgHidden,
  className,
}: EnrollmentStatusProps) {
  return (
    <div
      className={cx(
        'flex justify-center items-center gap-x-4 whitespace-nowrap',
        {'p-4 rounded-[15px]': !bgHidden},
        {
          'text-brown': status === 'approved',
          'text-[#ABA6A5]': status === 'pending' || status === 'waiting_list',
          'text-[#6DC200]': status === 'completed',
        },
        {
          'bg-superLightBrown': status === 'approved' && !bgHidden,
          'bg-[#fff4f4]': status === 'pending_signed' && !bgHidden,
          'bg-[#F8F8F8]':
            (status === 'pending' || status === 'waiting_list') && !bgHidden,
          'bg-[#F1FFDE]': status === 'completed' && !bgHidden,
        },
        className
      )}
    >
      <Image width={13} height={13} src={iconMap[status]} alt="" />
      <p className="font-medium">{textMap[status]}</p>
    </div>
  );
}
