export const hasCompletedAllCourses = (courses: Course[]) => {
  if (courses.length === 0) {
    return false;
  }

  return courses
    .filter((course) => !course.bonus)
    .every((course) => course.status === 'completed');
};

export const totalCompletedRequiredCourses = (courses: Course[]) =>
  courses.filter((course) => !course.bonus && course.status === 'completed')
    .length;

export const totalRequiredCourses = (courses: Course[]) =>
  courses.filter((course) => !course.bonus).length;

export const isPhoneNumber = (phone: any): boolean => {
  const regex = new RegExp(/^(?!00)[0-9]{7,15}$/);

  return regex.test(phone);
};
