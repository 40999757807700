import {format} from 'date-fns';
import it from 'date-fns/locale/it';

export const formatDate = (date: string | number, template: string) => {
  return format(new Date(date), template, {locale: it});
};

export const formatDateLong = (date: string | number) => {
  return format(new Date(date), 'd MMMM yyyy', {locale: it});
};

export const formatTime = (date: string) => {
  return format(new Date(date), 'HH:mm');
};

export const formatDay = (date: string) => {
  return format(new Date(date), 'dd');
};

export const formatMonthShort = (date: string) => {
  const monthstr = format(new Date(date), 'MMM', {locale: it});

  return monthstr.charAt(0).toUpperCase() + monthstr.slice(1);
};
