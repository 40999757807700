import {useRouter} from 'next/router';
import {FC, useEffect} from 'react';
import {useUser} from '../context/UserContext';
import RegisteredLanding from './RegisteredLanding';
import Wrapper from './Wrapper';

type AuthWrapperProps = {
  children: React.ReactElement;
};

function AuthWrapper(props: AuthWrapperProps) {
  const {user} = useUser();
  const {pathname, replace, asPath, isReady} = useRouter();

  const loading = !user || !isReady;

  useEffect(() => {
    if (pathname === '/' && !user && isReady) {
      replace({
        pathname: '/welcome',
      });
    }

    if (!user && isReady) {
      replace({
        pathname: '/login',
        query: {
          from: asPath,
        },
      });
    }
  }, [user, asPath, isReady, replace, pathname]);

  if (loading) {
    return (
      <Wrapper>
        <div className="flex-1 flex justify-center items-center">
          <span className="w-10 h-10 border-2 border-brown border-b-lightBrown rounded-full animate-spin" />
        </div>
      </Wrapper>
    );
  }

  const isRegistredRoute = pathname === '/profile' || pathname === '/chi-siamo';

  if (user.status === 'registered' && !isRegistredRoute) {
    return <RegisteredLanding />;
  }

  return <>{props.children}</>;
}

export const withAuthentication = (Comp: FC) => () =>
  (
    <AuthWrapper>
      <Comp />
    </AuthWrapper>
  );
