import Wrapper from './Wrapper';
import Header from './Header';
import {useRequiredUser} from '../context/UserContext';

export default function RegisteredLanding() {
  const {user} = useRequiredUser();

  return (
    <Wrapper>
      <div className="page-container">
        <Header />

        <div className="flex flex-col justify-center items-center text-center h-full">
          <h1 className="mb-2 text-[#3D251F] font-medium text-[32px] md:text-[48px] md:leading-[60px]">
            Registrazione in approvazione
          </h1>

          <p className="text-darkBrown mb-2 md:mb-6 text-[14px] md:text-[24px] leading-[14px] md:leading-[24px]">
            Hai completato la tua registrazione con successo.
          </p>

          <p>
            Grazie per il tempo che ci hai dedicato, stiamo verificando i tuoi
            dati.
            <br />
            Riceverai una mail a <strong>{user.email}</strong> di conferma
            quando la verifica avrà un esito positivo e potrai visualizzare i
            corsi.
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
