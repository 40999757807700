import {useCallback} from 'react';
import Link from 'next/link';
import Image from 'next/image';

import {useUser} from '../../context/UserContext';
import useResponsive from '../../hooks/useResponsive';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import Button from '../Button';

type HeaderProps = {
  hideButtons?: boolean;
};

export default function Header({hideButtons}: HeaderProps) {
  const {user} = useUser();

  const {lg} = useResponsive();

  const renderMenu = useCallback(() => {
    if (hideButtons) {
      return null;
    }
    if (!user) {
      return (
        <Link href="/login">
          <Button variant="dark">Accedi</Button>
        </Link>
      );
    }

    if (lg) {
      return <DesktopMenu />;
    }

    return <MobileMenu />;
  }, [hideButtons, lg, user]);

  return (
    <div className="w-full max-w-[1220px] flex justify-between items-center h-28 lg:h-32 print:hidden">
      <Link href="/">
        <Image
          src="/assets/logo.svg"
          width={200}
          height={46}
          className="h-10 md:h-12 md:w-52"
          alt="Allergan Aesthetics, a division of AbbVie"
        />
      </Link>

      {renderMenu()}
    </div>
  );
}
