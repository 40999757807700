import Link from 'next/link';
import cx from 'classnames';

type HeaderLinkProps = {
  path: string;
  children: React.ReactNode;
  active?: boolean;
  className?: string;
};

export default function HeaderLink(props: HeaderLinkProps) {
  return (
    <Link
      href={props.path}
      className={cx(
        'text-sm border-b',
        {
          'border-b-transparent': !props.active,
          'border-b-darkBrown font-medium': props.active,
        },
        props.className
      )}
    >
      {props.children}
    </Link>
  );
}
